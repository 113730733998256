import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import Home from "../pages/Home/Home"
import Login from "../pages/Login/Login"
import Header from "../components/Header/Header"
import DailyReport from "../pages/DailyReport/DailyReport"
import { getUserRole } from "../services/baseServices"
import AdminRoute from "./AdminRoute"
import { Management } from "../pages/Management/Management"
import ManageItems from "../pages/ManagementPages/ManageItems"
import ManageMartialArts from "../pages/ManagementPages/ManageMartialArts"
import AddUser from "../pages/ManageUser/AddUser"
import { useEffect, useState } from "react"
import UsersList from "../pages/ManageUser/UsersList"
import ProtectedRoute from "./ProtectedRoute"


const AppRoutes = () => {

    const [userRole, setUserRole] = useState(getUserRole());

    const handleStorageChange = () => {
        if (localStorage.getItem('frotaToken')) {
            setUserRole(getUserRole());
        } else {
            return <Navigate to={'/'} />
        }
        console.log("token changes")
    };

    useEffect(() => {
        handleStorageChange();
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [localStorage])


    return (
        <Router>
            <Header userRole={userRole} />
            <div className="main-container">
                <Routes>
                    <Route path="/" element={<Login isAdmin={false} />}></Route>
                    <Route path="/admin-login" element={<Login isAdmin={true} />}></Route>
                    <Route path="/home" element={<ProtectedRoute element={<Home />} userRole={userRole} />}></Route>
                    <Route path="/daily-report" element={<ProtectedRoute element={<DailyReport />} userRole={userRole} />}></Route>
                    <Route path="/management" element={<AdminRoute element={<Management />} userRole={userRole} adminOnly />}></Route>
                    <Route path="/manage-items" element={<AdminRoute element={<ManageItems />} userRole={userRole} adminOnly />}></Route>
                    <Route path="/manage-martial-arts" element={<AdminRoute element={<ManageMartialArts />} userRole={userRole} adminOnly />}></Route>
                    <Route path="/add-user" element={<AdminRoute element={<AddUser />} userRole={userRole} adminOnly />}></Route>
                    <Route path="/add-user/:id" element={<AdminRoute element={<AddUser />} userRole={userRole} adminOnly />}></Route>
                    <Route path="/users-list" element={<AdminRoute element={<UsersList />} userRole={userRole} adminOnly />}></Route>
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </Router>
    )
}

export default AppRoutes