import React, { useEffect, useState } from 'react';
import './ManageUser.css';

import { BASE_URL, passwordPattern } from '../../constants';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import Loader from '../../components/Loader/Loader';
import { useNavigate, useParams } from 'react-router';
import { createHttpService } from '../../services/httpService';
import { FROTA_BACKEND_URL } from '../../config';
import { User } from './UsersList';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import OverlayLoader from '../../components/Loader/OverlayLoader';

interface AddUserProps { }

const AddUser: React.FC<AddUserProps> = () => {

    const { id } = useParams<string>();

    const navigate = useNavigate();

    const userToken = localStorage.getItem('frotaToken') || '';
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [clientDescription, setClientDescription] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string | File | null>(null);

    const [viewPassword, setViewPassword] = useState<boolean>(false);
    const [isDeleteConfirmationModal, setIsDeleteConfirmationModal] = useState<boolean>(false);



    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!name) {
            toast.warn('Please Enter a Name');
            return;
        }
        if (!phone) {
            toast.warn('Please Enter a Phone Number.');
            return;
        }
        if (!email) {
            toast.warn('Please Enter an Email Address.');
            return;
        }
        if (!password && !id) {
            toast.warn('Please Enter a Password.');
            return;
        }
        const isValidPassword = passwordPattern.test(password);
        if (password && !isValidPassword) {
            toast.error('Password must contain at least One Uppercase letter, one Lowercase letter, one Number, and one Special Character, and be at least 8 characters long.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);

        if (password) {
            formData.append('password', password);
        }

        if (clientDescription) {
            formData.append('description', clientDescription);
        }

        // if (profileImage) {
        //     formData.append('image', profileImage);
        // }

        try {
            if (!id) {
                // Create User
                const response = await fetch(`${FROTA_BACKEND_URL}/admin/createUser`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${userToken}`
                    },
                    body: formData
                });

                const data = await response.json();

                if (response.ok) {
                    toast.success('User Created Successfully.');
                    setIsLoading(false);
                } else {
                    console.error('User sign-up failed:', data);
                    toast.error(data.message);
                    setIsLoading(false);
                }
            } else if (id) {
                // Update User
                const response = await fetch(`${FROTA_BACKEND_URL}/admin/update_user/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${userToken}`
                    },
                    body: formData
                });

                const data = await response.json();

                if (response.ok) {
                    toast.success('User Updated Successfully.');
                    setIsLoading(false);
                } else {
                    console.error('User update failed:', data);
                    toast.error(data.message);
                    setIsLoading(false);
                }
            }

        } catch (error) {
            console.error('An error occurred:', error);
            setIsLoading(false);
            toast.error('An unexpected error occurred.');
        }
    };

    const handleDeleteUser = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${FROTA_BACKEND_URL}/admin/remove_user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${userToken}`
                },
            });

            const data = await response.json();

            if (response.ok) {
                toast.success('User Deleted Successfully.');
                setIsLoading(false);
                setTimeout(() => {
                    navigate('/users-list')
                }, 1000);
            } else {
                console.error('User deletion failed:', data);
                toast.error(data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    const getUserData = async () => {
        setIsLoading(true)
        const httpService = createHttpService(FROTA_BACKEND_URL);

        try {
            const result = await httpService.get<User>(`/admin/users/${id}`);

            console.log(result);

            if (!result.success || !result.data) {
                throw new Error(result.message ?? 'getUsersList(): Unknown error');
            }

            setName(result.data.name);
            setPhone(result.data.phone);
            setEmail(result.data.email);
            setClientDescription(result.data.description);
            setProfileImage(result.data.avatar);
            // return result.data;
        } catch (error) {
            throw new Error(
                `getUsersList(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {

        if (id) {
            getUserData();
        }
    }, []);

    return (
        <div className='add-user-container'>
            <OverlayLoader isLoading={isLoading} />
            <form onSubmit={handleSubmit}>
                <h2 className='title'>{id ? "UPDATE USER" : "ADD USER"}</h2>
                <div className="form-container">
                    <div className="left-container">
                        <div className='form-field'>
                            <label htmlFor='name'>Name: </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                placeholder="Enter User's Name"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='email'>Email: </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder="Enter User's Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="right-container">
                        <div className='form-field'>
                            <label htmlFor='phone'>Phone: </label>
                            <input
                                type="text"
                                id="phone"
                                value={phone}
                                placeholder="Enter Phone Number"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className='form-field'>
                            <label htmlFor='password'>Password: </label>
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                placeholder="Enter User's Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className='view-password-icon-container'>
                                <FontAwesomeIcon
                                    icon={viewPassword ? faEye : faEyeSlash}
                                    onClick={() => setViewPassword(!viewPassword)}
                                    className='view-password-icon'
                                />
                            </div>
                        </div>
                        {/* <ImageUploader profileImage={profileImage} setProfileImage={setProfileImage} /> */}
                    </div>
                </div>

                <div className="bottom-container">
                    <div className='form-field'>
                        <label htmlFor='clientDescription'>User Description: </label>
                        <textarea
                            id="clientDescription"
                            value={clientDescription}
                            rows={4}
                            placeholder='Enter Some Employee Description...'
                            onChange={(e) => setClientDescription(e.target.value)}
                        />
                    </div>

                    <div className="btn-container">
                        {id && (<button type="button"
                            className='delete-btn'
                            style={{
                                background: '#dedede',
                                color: '#222',
                            }}
                            onClick={() => setIsDeleteConfirmationModal(true)}>
                            Delete User
                        </button>)}

                        <button type="submit" className='submit-btn'>{id ? "Update User" : "Add User"}</button>
                    </div>

                    <ConfirmationModal
                        isOpen={isDeleteConfirmationModal}
                        onRequestClose={() => setIsDeleteConfirmationModal(false)}
                        onConfirm={handleDeleteUser}
                        message="Are you sure you want to delete this user?"
                    />
                </div>
            </form >
        </div >
    );
};

export default AddUser;
