import React from 'react';
import { Navigate } from 'react-router-dom';
import OverlayLoader from '../components/Loader/OverlayLoader';

interface AdminRouteProps {
    element: React.ReactElement;
    adminOnly: boolean;
    userRole: string | null;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element, adminOnly, userRole }) => {
    // Render the component based on user role and route requirements
    return (
        // If userRole is not loaded yet, render a loading indicator
        // If user is authenticated and has admin access or not admin-only route, render the component
        (userRole === 'admin' && adminOnly) || !adminOnly ? (
            element
        ) : (
            // If user is not authenticated or doesn't have admin access, redirect to login page
            <Navigate to="/admin-login" replace />
        )
    );
};

export default AdminRoute;
