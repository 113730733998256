import { FROTA_BACKEND_URL } from "../config";
import { Item } from "../config/types";
import { createHttpService } from "./httpService";

export const handleFetchItems = async () => {
    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.get<Item[]>(`/items`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'handleFetchItems(): Unknown error');
        }

        return result.data;
    } catch (error) {
        throw new Error(
            `handleFetchItems(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const createItem = async (item: Item) => {

    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.post(`/items`, {
            name: item.name,
            price: item.price,
            quantity: 1,
        });

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'createItem(): Unknown error');
        }

        return result;
    } catch (error) {
        throw new Error(
            `createItem(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const updateItem = async (item: Item) => {

    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.put(`/items/${item.id}`, {
            price: item.price,
        });

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'updateItem(): Unknown error');
        }

        return result;
    } catch (error) {
        throw new Error(
            `updateItem(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const deleteItem = async (itemId: number) => {

    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.deleteMethod(`/items/${itemId}`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'updateItem(): Unknown error');
        }

        return result;
    } catch (error) {
        throw new Error(
            `updateItem(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}
