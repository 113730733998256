import { toast } from 'react-toastify';
import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export interface ApiResponse<T> {
    success: boolean;
    data?: T;
    message?: string;
    statusCode: number;
}

interface TribeShareApiResponse<T> {
    data?: T;
    message?: string;
    success?: boolean;
}

export const clearSession = () => {
    localStorage.clear();
    localStorage.removeItem('frotaToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/';
};

export const createHttpService = (baseURL = 'https://reports-backend.frota-team.ch') => {
    const instance = axios.create({
        baseURL,
        timeout: 10000, // Set a timeout value in milliseconds
        headers: {
            'Content-Type': 'application/json',
        },
        signal: AbortSignal.timeout(10000), // Set a timeout value in milliseconds
    });

    // Axios request interceptor
    instance.interceptors.request.use(
        (config: InternalAxiosRequestConfig<unknown>) => {
            const accessToken = localStorage.getItem('frotaToken');
            if (accessToken) {
                config.headers.set('Authorization', `Bearer ${accessToken}`);
                config.headers.set(
                    'Content-Type',
                    config.url?.includes('/task/submission') || config.url?.includes('/attachment/upload') ||
                        (config.url?.includes('me/profileImage') && config.method === 'post')
                        ? 'multipart/form-data'
                        : 'application/json',
                );
                config.headers.set('Accept', 'application/json');
            }
            return config;
        },
        (error: Error) => Promise.reject(error),
    );

    // Axios response interceptor for handling token refresh and error responses
    let refreshPromise: Promise<{ token: string; refreshToken: string }> | null = null;
    const clearPromise = (): void => { refreshPromise = null; };

    const refreshUserToken = async () => {
        try {
            const response = await instance.post('/refreshToken', { refreshToken: localStorage.getItem('refreshToken') });
            return response.data.responseData;
        } catch (error) {
            localStorage.removeItem('frotaToken');
            localStorage.removeItem('refreshToken');
            return {};
        }
    };

    instance.interceptors.response.use(
        (response) => response, // Allow successful responses through
        async (error) => {
            const config = error.config;
            const errorMessage = error?.response?.data?.message || 'Something went wrong. Please try again later.';
            const statusCode = error?.response?.status;

            if (statusCode === 401 && errorMessage.toLowerCase() === 'expired token' && !config._retry) {
                config._retry = true;
                toast.loading('Attempting to refresh token...');
                if (!refreshPromise) {
                    refreshPromise = refreshUserToken().finally(clearPromise);
                }
                const { token, refreshToken } = await refreshPromise;
                if (token) {
                    localStorage.setItem('frotaToken', token);
                    localStorage.setItem('refreshToken', refreshToken);
                    config.headers.authorization = `Bearer ${token}`;
                    toast.dismiss();
                    return instance(config);
                }
            } else if (statusCode) {
                toast.error(errorMessage);
                if (statusCode === 401 || statusCode === 440) {
                    setTimeout(() => clearSession(), 3000);
                }
            }
            return Promise.reject(error);
        },
    );

    // API methods

    const get = async <T>(path: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
        try {
            const response: AxiosResponse<TribeShareApiResponse<T>> = await instance.get(path, config);
            const isSuccessCode = response.status >= 200 && response.status < 300;
            return {
                success: isSuccessCode,
                data: response.data.data,
                message: response.data.message,
                statusCode: response.status,
            };
        } catch (error: unknown) {
            return handleErrorResponse(error);
        }
    };

    const post = async <R>(path: string, data?: FormData | object, config?: AxiosRequestConfig): Promise<ApiResponse<R>> => {
        try {
            const response: AxiosResponse<TribeShareApiResponse<R>> = await instance.post(path, data, config);
            const isSuccessCode = response.status >= 200 && response.status < 300;
            return {
                success: isSuccessCode,
                data: response.data.data,
                message: response.data.message,
                statusCode: response.status,
            };
        } catch (error: unknown) {
            return handleErrorResponse(error);
        }
    };

    const put = async <R>(path: string, data?: object, config?: AxiosRequestConfig): Promise<ApiResponse<R>> => {
        try {
            const response: AxiosResponse<TribeShareApiResponse<R>> = await instance.put(path, data, config);
            const isSuccessCode = response.status >= 200 && response.status < 300;
            return {
                success: isSuccessCode,
                data: response.data.data,
                message: response.data.message,
                statusCode: response.status,
            };
        } catch (error: unknown) {
            return handleErrorResponse(error);
        }
    };

    const patch = async <R>(path: string, data?: object, config?: AxiosRequestConfig): Promise<ApiResponse<R>> => {
        try {
            const response: AxiosResponse<TribeShareApiResponse<R>> = await instance.patch(path, data, config);
            const isSuccessCode = response.status >= 200 && response.status < 300;
            return {
                success: isSuccessCode,
                data: response.data.data,
                message: response.data.message,
                statusCode: response.status,
            };
        } catch (error: unknown) {
            return handleErrorResponse(error);
        }
    };

    const deleteMethod = async <T>(path: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
        try {
            const response: AxiosResponse<TribeShareApiResponse<T>> = await instance.delete(path, config);
            const isSuccessCode = response.status >= 200 && response.status < 300;
            return {
                success: isSuccessCode,
                data: response.data.data,
                message: response.data.message,
                statusCode: response.status,
            };
        } catch (error: unknown) {
            return handleErrorResponse(error);
        }
    };

    const handleErrorResponse = (error: unknown): ApiResponse<any> => {
        if (axios.isAxiosError(error)) {
            const errorMessageFromAPI = error.response?.data?.message;
            const statusCode = error.response?.status ?? 500;
            return {
                success: false,
                message: errorMessageFromAPI || 'Sorry, something went wrong. Please try again later.',
                statusCode,
            };
        } else {
            return { success: false, message: 'Internal Server Error', statusCode: -1 };
        }
    };

    return {
        get,
        post,
        put,
        patch,
        deleteMethod,
    };
};
