import React, { useEffect, useState } from 'react'
import './ManagementPages.css'
import { StyledTextField } from '../../components/StyledComponents/StyledInput';
import StyledButton from '../../components/StyledComponents/StyledButton';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { createItem, deleteItem, handleFetchItems, updateItem } from '../../services/itemServices';
import { Item } from '../../config/types';
import UpdateItemModal from '../../components/Modals/UpdateItemModal';
import OverlayLoader from '../../components/Loader/OverlayLoader';

const ManageItems = () => {

    const [isListLoading, setIsListLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdateItemModal, setIsUpdateItemModal] = useState<boolean>(false);
    const [itemsList, setItemsList] = useState<Item[]>([])
    const [item, setItem] = useState<Item>({
        id: 0,
        name: '',
        price: 0
    });

    const [modalItem, setModalItem] = useState<Item>({
        id: 0,
        name: '',
        price: 0
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name, value } = event.target;
        setItem((prevItem) => ({ ...prevItem, [name]: value }))
    }

    const getItems = async () => {
        try {
            setIsListLoading(true);
            const response = await handleFetchItems();
            setItemsList(response);
            setIsListLoading(false);
        } catch (error) {
            console.error("Error: ", error)
        } finally {
            setIsListLoading(false);
        }
    }

    const handleItemClick = (item: Item) => {
        setModalItem(item);
        setIsUpdateItemModal(true);
        console.log('first')
    }

    const handleCreateItem = async () => {

        if (!item.name) {
            return toast.error('Please enter an Item Name.')
        }

        setIsLoading(true);
        try {
            const response = createItem(item);

            if ((await response).success) {
                setIsLoading(false);
                toast.success("Item Created Successfully.")
                setItem({
                    id: 0,
                    name: '',
                    price: 0
                })
                getItems();
            }

        } catch (error) {
            throw new Error(
                `handleCreateItem(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    const handleUpdateItem = async (updatedItem: Item) => {
        setIsLoading(true);

        if (!updatedItem.name) {
            return toast.error('Please enter an Item Name.')
        }
        try {
            const response = updateItem(updatedItem);

            if ((await response).success) {
                setIsLoading(false);
                toast.success("Item Updated Successfully.")
                setModalItem({
                    id: 0,
                    name: '',
                    price: 0
                })
                setIsUpdateItemModal(false);
                getItems();
            }

        } catch (error) {
            throw new Error(
                `handleUpdateItem(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeleteItem = async (itemId?: number) => {
        if (!itemId) {
            console.error("Not Able to Delete Item of Id: ", itemId)
            return;
        }
        try {
            setIsLoading(true);
            const response = deleteItem(itemId);

            if ((await response).success) {
                setIsLoading(false);
                toast.success("Item Deleted Successfully.")
                setModalItem({
                    id: 0,
                    name: '',
                    price: 0
                })
                setIsUpdateItemModal(false);
                getItems();
            }

        } catch (error) {
            throw new Error(
                `handleDeleteItem(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    return (
        <div>
            <div className='create-item-container'>
                <StyledTextField
                    id="outlined-basic"
                    label="Item Name"
                    name='name'
                    type='text'
                    variant="outlined"
                    className='my-2'
                    style={{ minWidth: '250px', marginRight: 14 }}
                    value={item.name}
                    onChange={handleInputChange}
                />
                <StyledTextField
                    id="outlined-basic"
                    label="Item Price"
                    name='price'
                    type='number'
                    variant="outlined"
                    className='my-2'
                    style={{ minWidth: '250px', marginRight: 14 }}
                    value={item.price}
                    onChange={handleInputChange}
                />
                <StyledButton className="my-2"
                    style={{ padding: '6px 14px' }}
                    onClick={handleCreateItem}>{isLoading ? <CircularProgress /> : 'Create Item'}</StyledButton>
            </div>

            <div className="list-container">
                <h2 className='title'>ITEMS LIST</h2>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <OverlayLoader isLoading={isLoading} />
                        {itemsList?.length > 0 ? (
                            <tbody>
                                {itemsList.map(item => (
                                    <tr key={item.id} onClick={() => handleItemClick(item)}>
                                        <td>{item.id}</td>
                                        <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{item.name}</td>
                                        <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{item.price}</td>
                                        <td><StyledButton className="my-2"
                                            style={{ padding: '6px 14px', fontSize: 13, alignItems: 'baseline' }}
                                            onClick={() => handleDeleteItem(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} size='xs' className='me-1' /> {' '} Delete
                                        </StyledButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={7} className='no-data-message'>
                                        <p>No Items Available</p>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
                <UpdateItemModal
                    isOpen={isUpdateItemModal}
                    onRequestClose={() => setIsUpdateItemModal(false)}
                    onSave={handleUpdateItem}
                    onDelete={handleDeleteItem}
                    item={modalItem}
                    isEditing={true}
                />
            </div>
        </div >
    )
}

export default ManageItems