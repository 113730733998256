import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
  background-color: var(--red);
  color: white;
  font-weight: 600;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  text-transform: none;

  &:hover {
    background-color: var(--hover-color);
  }
`;

export default StyledButton;
