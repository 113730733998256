import React, { useState } from 'react';
import './header.css';
import logo from '../../assets/Images/frota-logo.webp';
import { Link, useLocation } from 'react-router-dom';
import AdminNavLinks from './AdminNavLinks';
import UserNavLinks from './UserNavLinks';

interface HeaderProps {
    userRole: string | null;
}

const Header: React.FC<HeaderProps> = ({ userRole }) => {
    const location = useLocation();
    const currentUrl = location.pathname;

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to={'/'}>
                <img src={logo} height="72" alt="Frota Logo" />
            </Link>
            {currentUrl !== '/' && currentUrl !== '/admin-login' && currentUrl !== '/forgot-password' && currentUrl !== '/reset-password' ? (
                <>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed}
                        aria-label="Toggle navigation" onClick={handleNavCollapse}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            {userRole === 'admin' ? (
                                <AdminNavLinks onClick={handleNavCollapse} />
                            ) : (
                                <UserNavLinks onClick={handleNavCollapse} userRole={userRole} />
                            )}
                        </ul>
                    </div>
                </>
            ) : (
                <>
                    {currentUrl === '/forgot-password' && (
                        <li className="nav-item" style={{ listStyle: 'none' }}>
                            <Link to={'/'} className="nav-link login-nav-link">BACK TO LOGIN</Link>
                        </li>
                    )}
                    {currentUrl === '/reset-password' && (
                        <li className="nav-item" style={{ listStyle: 'none' }}>
                            <Link to={'/'} className="nav-link login-nav-link">BACK TO LOGIN</Link>
                        </li>
                    )}
                </>
            )}
        </nav>
    );
};

export default Header;