import React from 'react';
import Modal from 'react-modal';

const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm, message }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Confirmation Modal"
        >
            <h2>Confirmation</h2>
            <p>{message}</p>
            <div className="modal-btn-container">
                <button className='cancel-button' onClick={onRequestClose}>Cancel</button>
                <button className='modal-button' onClick={onConfirm}>Confirm</button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
