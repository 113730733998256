import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './modal.css'
import { Item, Merchandise } from '../../config/types';

import { selectStyles } from '../StyledComponents/SelectStyles';
import { handleFetchItems } from '../../services/itemServices';
import Select from 'react-select';
import { toast } from 'react-toastify';

interface UpdateItemModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (item: Item) => void;
    onDelete?: (index?: number) => void;
    item: Item | null;
    isEditing: boolean;
}

const UpdateItemModal: React.FC<UpdateItemModalProps> = ({
    isOpen,
    onRequestClose,
    onSave,
    onDelete,
    item,
    isEditing
}) => {
    const [formData, setFormData] = useState<Item>({
        id: item?.id || 0,
        name: item?.name || '',
        price: item?.price || 0,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.name) {
            return toast.error('Please Enter a Name to Update.')
        }
        onSave(formData);
    };

    useEffect(() => {
        setFormData({
            id: item?.id || 0,
            name: item?.name || '',
            price: item?.price || 0,
        })
    }, [item])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="AppointmentModal"
            overlayClassName="AppointmentModal-overlay"
            ariaHideApp={false}
            contentLabel={isEditing ? 'Edit Item' : 'Add New Item'}
        >
            <h2 className='text-center'>{isEditing ? 'Edit Item' : 'Add New Item'}</h2>
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <label>Item Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Price:</label>
                    <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                    />
                </div>

                <div className="modal-buttons">
                    <button type="submit" className="save-btn">Save</button>
                    {isEditing && onDelete && (
                        <button type="button" className="delete-btn" onClick={() => onDelete(item?.id)}>Delete</button>
                    )}
                    <button type="button"
                        className="close-button close-comment-modal me-2"
                        style={{ background: 'var(--cancel-color)', color: '#000' }}
                        onClick={onRequestClose}>Cancel</button>
                </div>
            </form>
        </Modal>
    );
};

export default UpdateItemModal;
