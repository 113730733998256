import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    element: React.ReactElement;
    userRole: string | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, userRole }) => {
    // Render the component based on user role and route requirements
    return (
        // If userRole is not loaded yet, render a loading indicator
        // If user is authenticated and has admin access or not admin-only route, render the component
        (userRole === 'admin' || userRole === 'user') ? (
            element
        ) : (
            // If user is not authenticated or doesn't have admin access, redirect to login page
            <Navigate to="/login" replace />
        )
    );
};

export default ProtectedRoute;
