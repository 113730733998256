import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './modal.css'
import { MartialArt } from '../../config/types';
import { toast } from 'react-toastify';

interface UpdateMartialArtModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (martialArt: MartialArt) => void;
    onDelete?: (index?: number) => void;
    martialArt: MartialArt | null;
    isEditing: boolean;
}

const UpdateMartialArtModal: React.FC<UpdateMartialArtModalProps> = ({
    isOpen,
    onRequestClose,
    onSave,
    onDelete,
    martialArt,
    isEditing
}) => {
    const [formData, setFormData] = useState<MartialArt>({
        id: martialArt?.id || 0,
        name: martialArt?.name || '',
        price: martialArt?.price || 0,
        duration: martialArt?.duration || '',
    });

    useEffect(() => {
        setFormData({
            id: martialArt?.id || 0,
            name: martialArt?.name || '',
            price: martialArt?.price || 0,
            duration: martialArt?.duration || ''
        })
    }, [martialArt])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.name) {
            return toast.error('Please Enter a Name to Update.')
        }
        if (!formData.duration) {
            return toast.error('Please Enter a Duration to Update.')
        }
        onSave(formData);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="AppointmentModal"
            overlayClassName="AppointmentModal-overlay"
            ariaHideApp={false}
            contentLabel={isEditing ? 'Edit Martial Art' : 'Add New Martial Art'}
        >
            <h2 className='text-center'>{isEditing ? 'Edit Martial Art' : 'Add New Martial Art'}</h2>
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <label>Martial Art Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Price:</label>
                    <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Duration:</label>
                    <input
                        type="text"
                        name="duration"
                        value={formData.duration}
                        onChange={handleChange}
                    />
                </div>

                <div className="modal-buttons">
                    <button type="submit" className="save-btn">Save</button>
                    {isEditing && onDelete && (
                        <button type="button" className="delete-btn" onClick={() => onDelete(martialArt?.id)}>Delete</button>
                    )}
                    <button type="button"
                        className="close-button close-comment-modal me-2"
                        style={{ background: 'var(--cancel-color)', color: '#000' }}
                        onClick={onRequestClose}>Cancel</button>
                </div>
            </form>
        </Modal>
    );
};

export default UpdateMartialArtModal;
