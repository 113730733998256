// MembershipReport.tsx
import React, { useState } from 'react';
import MembershipModal from '../Modals/MembershipModal';
import { Membership } from '../../config/types';

interface MembershipReportProps {
    memberships: Membership[];
    setMemberships: (membership: Membership[]) => void;
}

interface CurrentMembership extends Membership {
    id: number;
}

const MembershipReport: React.FC<MembershipReportProps> = ({ memberships, setMemberships }) => {

    const [membershipModalIsOpen, setMembershipModalIsOpen] = useState(false);
    const [isEditingMembership, setIsEditingMembership] = useState(false);
    const [currentMembership, setCurrentMembership] = useState<CurrentMembership | null>(null);

    const openMembershipModal = () => setMembershipModalIsOpen(true);
    const closeMembershipModal = () => {
        setMembershipModalIsOpen(false);
        setCurrentMembership(null);
        setIsEditingMembership(false);
    };

    const handleAddNewMembership = () => {
        setCurrentMembership({
            id: 0,
            duration: '',
            martialArtId: 0,
            martialArt: '',
            studentName: '',
            price: 0,
            paymentType: ''
        });
        setIsEditingMembership(false);
        openMembershipModal();
    };

    const handleEditMembership = (membership: CurrentMembership) => {
        setCurrentMembership(membership);
        setIsEditingMembership(true);
        openMembershipModal();
    };

    const handleSaveMembership = (membership: CurrentMembership) => {
        if (isEditingMembership) {
            const tempMemberships = [...memberships];
            tempMemberships.splice(membership.id, 1, membership); // Edit the element from memberships Array at membershipIndex
            setMemberships(tempMemberships);
        } else {
            setMemberships([...memberships, membership]);
        }
        closeMembershipModal();
    };

    const handleDeleteMembership = (membershipIndex?: number) => {
        if (currentMembership && (membershipIndex || membershipIndex === 0)) {
            const tempMemberships = [...memberships];
            tempMemberships.splice(membershipIndex, 1); // Delete the element from memberships Array at membershipIndex
            setMemberships(tempMemberships);
        }
        closeMembershipModal();
    };

    return (
        <div className="section">
            <div className="section-header">
                <h3>MEMBERSHIPS</h3>
                <button className="add-btn" onClick={handleAddNewMembership}>ADD NEW</button>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>DURATION</th>
                        <th>MARTIAL ART(S)</th>
                        <th>STUDENT NAME</th>
                        <th>PRICE</th>
                        <th>TYPE OF PAYMENT</th>
                    </tr>
                </thead>
                <tbody>
                    {memberships?.map((membership, index) => (
                        <tr key={index} onClick={() => handleEditMembership({ ...membership, id: index })}>
                            <td>{membership.duration}</td>
                            <td>{membership.martialArt}</td>
                            <td>{membership.studentName}</td>
                            <td>${membership.price}</td>
                            <td>{membership.paymentType}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={4} className="total-label">TOTAL MEMBERSHIPS</td>
                        <td className="total-value">${memberships?.length > 0 ? memberships.reduce((acc, cur) => acc + cur.price, 0) : 0}</td>
                    </tr>
                </tfoot>
            </table>

            <MembershipModal
                isOpen={membershipModalIsOpen}
                onRequestClose={closeMembershipModal}
                onSave={handleSaveMembership}
                onDelete={isEditingMembership ? handleDeleteMembership : undefined}
                membership={currentMembership}
                isEditing={isEditingMembership}
            />
        </div>
    );
};

export default MembershipReport;
