export const getUserRole = () => {
    if (!localStorage.getItem('frotaToken')) {
        return null;
    }
    const userJwt = localStorage.getItem('frotaToken') || ""
    const user = JSON.parse(atob(userJwt.split('.')[1]))

    return user.role;
}

export const clearSession = () => {
    localStorage.removeItem('frotaToken');
    localStorage.removeItem('refreshToken');
}

