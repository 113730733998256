import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
    margin: 0,
    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(14px, 6px) scale(1)"
    },
    "& .MuiInputLabel-root": {
        // color: "green"
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "var(--hover-color)",
    },
    "& .MuiInputBase-root": {
        color: "var(--hover-color)",
    },
    "& .MuiInputBase-input": {
        padding: '18px 9px',
        border: 'none',
        margin: 0
    },
    "& label.Mui-focused": {
        color: 'var(--hover-color)'

    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: 'var(--hover-color)'
        }
    }

});