import React from 'react';
import './loader.css'; 

interface OverlayLoaderProps {
    isLoading: boolean;
}

const OverlayLoader: React.FC<OverlayLoaderProps> = ({ isLoading }) => {
    return (
        <div className={`loader-overlay ${isLoading ? 'active' : ''}`}>
            <div className="loader"></div>
        </div>
    );
};

export default OverlayLoader;
