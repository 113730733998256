import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './modal.css'
import { Item, Merchandise } from '../../config/types';

import { selectStyles } from '../StyledComponents/SelectStyles';
import { handleFetchItems } from '../../services/itemServices';
import Select from 'react-select';
import { toast } from 'react-toastify';

interface MerchandiseModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (merchandise: CurrentMerchandise) => void;
    onDelete?: (index?: number) => void;
    merchandise: CurrentMerchandise | null;
    isEditing: boolean;
}

interface CurrentMerchandise extends Merchandise {
    id: number;
}

const MerchandiseModal: React.FC<MerchandiseModalProps> = ({
    isOpen,
    onRequestClose,
    onSave,
    onDelete,
    merchandise,
    isEditing
}) => {
    const [itemsList, setItemsList] = useState<Item[]>([])
    const [selectedItem, setSelectedItem] = useState({ value: '', label: '', price: 0 });

    const [formData, setFormData] = useState<CurrentMerchandise>({
        id: 0,
        itemId: merchandise?.itemId || 1,
        item: merchandise?.item || '',
        pricePerItem: merchandise?.pricePerItem || 0,
        price: merchandise?.price || 0,
        quantity: merchandise?.quantity || 1,
    });

    const getItems = async () => {
        try {
            const response = await handleFetchItems();
            setItemsList(response);
        } catch (error) {
            console.error("Error: ", error)
        }
    }

    useEffect(() => {
        getItems();
        if (merchandise) {
            setFormData(merchandise);
        }
    }, [merchandise]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        if (name === 'price') {
            setFormData({ ...formData, price: parseInt(value) });
            return;
        }
        if (name === 'quantity') {
            setFormData({ ...formData, quantity: parseInt(value), price: selectedItem.price * parseInt(value) });
            return;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectItem = (selectedOption: any) => {
        setSelectedItem(selectedOption);
        setFormData({
            ...formData,
            item: selectedOption.label,
            pricePerItem: selectedOption.price,
            price: selectedOption.price * formData.quantity
        })
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.item) {
            return toast.error('Please Select an Item to Add.')
        }
        onSave(formData);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="AppointmentModal"
            overlayClassName="AppointmentModal-overlay"
            ariaHideApp={false}
            contentLabel={isEditing ? 'Edit Merchandise' : 'Add New Merchandise'}
        >
            <h2 className='text-center'>{isEditing ? 'Edit Merchandise' : 'Add New Merchandise'}</h2>
            <form onSubmit={handleSubmit}>
                <div className='form-group-container'>
                    <div className="form-group">
                        <label>Item:</label>
                        <Select
                            value={formData.item ?
                                { label: formData.item, value: formData.itemId }
                                : null}
                            placeholder="Select Item"
                            onChange={handleSelectItem}
                            options={itemsList?.map(item => ({
                                value: item.id,
                                label: item.name,
                                price: item.price
                            }))}
                            className='my-2'
                            styles={selectStyles}
                        />
                    </div>
                    <div className="form-group">
                        <label>Price per Item:</label>
                        <input
                            type="number"
                            name="pricePerItem"
                            value={formData.pricePerItem}
                            onChange={handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className='form-group-container'>
                    <div className="form-group">
                        <label>Price:</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Quantity:</label>
                        <input
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="modal-buttons">
                    <button type="submit" className="save-btn">Save</button>
                    {isEditing && onDelete && (
                        <button type="button" className="delete-btn" onClick={() => onDelete(merchandise?.id)}>Delete</button>
                    )}
                    <button type="button"
                        className="close-button close-comment-modal me-2"
                        style={{ background: 'var(--cancel-color)', color: '#000' }}
                        onClick={onRequestClose}>Cancel</button>
                </div>
            </form>
        </Modal>
    );
};

export default MerchandiseModal;
