import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { createHttpService } from '../../services/httpService';
import { FROTA_BACKEND_URL } from '../../config';
import Loader from '../../components/Loader/Loader';
import OverlayLoader from '../../components/Loader/OverlayLoader';

export interface User {
    id: number,
    name: string;
    email: string;
    phone: string;
    avatar: string;
    address: string;
    description: string;
}
const UsersList = () => {

    const navigate = useNavigate();

    const [usersList, setUsersList] = useState<User[]>([])
    const [isLoading, setIsLoading] = useState(true);

    const handleRecordClick = async (id: number) => {
        navigate(`/add-user/${id}`);
    }

    useEffect(() => {

        const getUsersList = async () => {
            setIsLoading(true)
            const httpService = createHttpService(FROTA_BACKEND_URL);

            try {
                const result = await httpService.get<any>(`/admin/users`);

                console.log(result);

                if (!result.success || !result.data) {
                    throw new Error(result.message ?? 'getUsersList(): Unknown error');
                }

                setUsersList(result.data);
                // return result.data;
            } catch (error) {
                throw new Error(
                    `getUsersList(): ${error instanceof Error ? error.message : 'Unknown error'}`,
                );
            } finally {
                setIsLoading(false);
            }
        };

        getUsersList();

    }, [])

    return (
        <div className="student-list-container">
            <h2 className='title text-center'>USERS LIST</h2>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            {/* <th>Address</th> */}
                            <th>Description</th>
                        </tr>
                    </thead>
                    <OverlayLoader isLoading={isLoading} />
                    {usersList?.length > 0 ? (
                        <tbody>
                            {usersList.map(user => (
                                <tr key={user.id} onClick={() => handleRecordClick(user.id)}>
                                    <td>{user.id}</td>
                                    <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{user.name}</td>
                                    <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{user.email}</td>
                                    <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{user.phone}</td>
                                    {/* <td>{user.address}</td> */}
                                    <td>{user.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={6} className='no-data-message'>
                                    <p>No Users Available</p>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    )
}

export default UsersList