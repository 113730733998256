import React from 'react'
import './Management.css'
import { Link } from 'react-router-dom'

export const Management = () => {
    return (
        <div className="management-container">
            <div className='col-3'>
                <div className='title-container'>
                    <h2 className='title'>MANAGE USERS</h2>
                </div>
                <div className='btn-container'>
                    <Link to='/add-user' className='btn-link'>
                        <button className='btn'>Add User</button>
                    </Link>
                    <Link to='/users-list' className='btn-link'>
                        <button className='btn'>List of Users</button>
                    </Link>
                </div>
            </div>
            <div className='col-3'>
                <div className='title-container'>
                    <h2 className='title'>MANAGE DETAILS</h2>
                </div>
                <div className='btn-container'>
                    <Link to='/manage-martial-arts' className='btn-link'>
                        <button className='btn'>Manage Martial Arts</button>
                    </Link>
                    <Link to='/manage-items' className='btn-link'>
                        <button className='btn'>Manage Items</button>
                    </Link>
                </div>
            </div>
            {/* <div className='col-3'>
                <div className='title-container'>
                    <h2 className='title'>STATISTICS</h2>
                </div>
                <div className='btn-container'>
                    <Link to='/classes-per-coach' className='btn-link'>
                        <button className='btn'>Monthly/Yearly Classes per Coach</button>
                    </Link>
                    <Link to='/classes-per-student' className='btn-link'>
                        <button className='btn'>Monthly/Yearly Classes per Student</button>
                    </Link>
                </div>
            </div> */}
        </div>
    )
}
