
import { useNavigate } from 'react-router'
import './home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';

const Home = () => {

  const navigate = useNavigate();

  return (
    <div>
      <div className='box-container'>
        <div className="box-button" onClick={() => navigate('/daily-report')}>
          <FontAwesomeIcon icon={faNewspaper}/>
          <span>Daily Report</span></div>
        <div className="box-button" onClick={() => navigate('')}><span>Button</span></div>
        <div className="box-button" onClick={() => navigate('')}><span>Button</span></div>
      </div>
    </div>
  )
}

export default Home