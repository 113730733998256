import { StylesConfig } from 'react-select';

interface OptionType {
    label: string;
    value: string | number;
}

export const selectStyles: StylesConfig<OptionType, false> = {
    control: (styles, { isFocused, isDisabled }) => ({
        ...styles,
        minWidth: '145px',
        backgroundColor: isFocused ? '#FFF' : 'white',
        borderColor: isFocused ? 'var(--red)' : '#ced4da',
        borderWidth: 1,
        boxShadow: isFocused ? '0 0 0 1px var(--red)' : '', // Box shadow when focused
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:hover': {
            borderColor: isFocused ? 'var(--red)' : '#adb5bd',
        },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected
            ? 'var(--red)'
            : isFocused
                ? '#3331'
                : 'transparent', // Adjust background based on state
        color: isSelected ? '#FFF' : '',
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:hover': {
            backgroundColor: isSelected ? 'var(--red)' : '#f8f9fa',
            color: isSelected ? '#FFF' : '',
        },
    }),
    menu: (styles) => ({
        ...styles,
        zIndex: 9999, // Ensure dropdown is above other elements
    }),
};
